import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

(function () {
    /*
 * Replace all SVG images with inline SVG
 */
    $('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });
})();


// close menu mobile after clicking in the link
// $(".menu-mobile-mc li a").click(function () {
//     $(".menu-icon").trigger("click");
// });

// vimeo start
const iframe = $('#video'),
      playBtn = $("#play-button"),
      player = new Vimeo.Player(iframe);

iframe.hide();

playBtn.click(function() {
  iframe.fadeIn(500);
  setTimeout(function(){ player.play(); }, 500);
});

player.on('pause', function() {
  iframe.fadeOut(500);
  setTimeout(function(){ player.pause(); }, 500);
});

//esc pause vimeo
document.onkeydown = function(evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
        isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
        console.log('pause here')
        player.pause();
    }
};
// player end


// direct browser to top right away
if (window.location.hash)
    scroll(0, 0);
// takes care of some browsers issue
setTimeout(function () { scroll(0, 0); }, 2);

$(function () {
    //your current click function
    $('.scroll').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: $($(this).attr('href')).offset().top + 'px'
        }, 1000, 'swing');
    });

    // if we have anchor on the url (calling from other page)
    if (window.location.hash) {
        // smooth scroll to the anchor id
        $('html,body').animate({
            scrollTop: $(window.location.hash).offset().top + 'px'
        }, 1000, 'swing');
    }
});


    // $(".hamburger").click(function(){
    //   $(this).toggleClass("is-active");
    // });